import "./styles.scss";

import React from "react";
import classnames from "classnames";

import Button from "../Button";

import { useApp } from "context/app";
import { useFavoriteInvestments } from "context/favorite_investments";
import { useCompareInvestments } from "context/compare_investments";
import { useFavoriteApartments } from "context/favorite_apartments";
import { useCompareApartments } from "context/compare_apartments";

import CPinIcon from "icons/facilities/c-pin.svg";
import CompareIcon from "icons/compare.svg";
import HeartIcon from "icons/heart.svg";
import ExitIcon from "icons/exit-modal.svg";
import { Link } from "gatsby";

const mainClass = "investment_list_item";

const InvestmentListItem = ({
  id,
  uri,
  title,
  investments,
  items,
  selected_view = 0,
}) => {
  const { setContactFormModal } = useApp();
  const { fav_investments, addToFavorite } = useFavoriteInvestments();
  const { fav_apartments, addToFavorite: addToApartmentFavorite } =
    useFavoriteApartments();
  const { compare_investments, addToCompare } = useCompareInvestments();
  const { compare_apartments, addToCompare: addApartmentToCompare } =
    useCompareApartments();

  const actions_data = [
    {
      icon: <CompareIcon />,
      label: "Porównaj",
      onClick: () =>
        selected_view === 0 ? addToCompare(id) : addApartmentToCompare(id),
      active:
        selected_view === 0
          ? compare_investments?.includes(id)
          : compare_apartments?.includes(id),
    },
    {
      key: "storage",
      icon: <HeartIcon />,
      label: "Do schowka",
      onClick: () =>
        selected_view === 0 ? addToFavorite(id) : addToApartmentFavorite(id),
      active:
        selected_view === 0
          ? fav_investments?.includes(id)
          : fav_apartments?.includes(id),
      toggle: {
        icon: <ExitIcon />,
        label: "Usuń",
      },
    },
  ];

  return (
    <div className={mainClass}>
      <Link
        to={uri}
        className={`${mainClass}__image`}
        style={{
          backgroundImage: `url(${investments?.mainImg?.sourceUrl})`,
        }}
      >
        {!!investments?.ready && (
          <div className={`${mainClass}__image__sold-out`}>
            <span>Inwestycja wyprzedana</span>
          </div>
        )}
        {investments?.labelState && (
          <div className={`${mainClass}__image__label`}>
            <span>{investments?.labelText}</span>
          </div>
        )}
      </Link>
      <div className={`${mainClass}__content`}>
        <div className={`${mainClass}__content__title`}>
          <div className={`${mainClass}__content__title__text`}>
            <Link to={uri}>
              <h3
                dangerouslySetInnerHTML={{
                  __html: investments?.mainTitle?.split("|")?.join("<br/>"),
                }}
              />
            </Link>
            <span>
              <CPinIcon />
              {investments?.shortLocation}
            </span>
          </div>
          <div className={`${mainClass}__content__title__actions`}>
            <div className={`${mainClass}__content__title__actions__items`}>
              {actions_data?.map(
                ({ icon, label, onClick, active, toggle }, index) => (
                  <button
                    className={classnames({ active: !!active && !!!toggle })}
                    key={index}
                    onClick={onClick}
                  >
                    {!!active && !!toggle ? (
                      <>
                        {toggle?.label} {toggle?.icon}
                      </>
                    ) : (
                      <>
                        {icon} {label}
                      </>
                    )}
                  </button>
                )
              )}
            </div>
            <Button
              variant="navy"
              onClick={() => setContactFormModal({ investment: title })}
            >
              Zapytaj
            </Button>
          </div>
        </div>
        <div className={`${mainClass}__content__info`}>
          {items?.map(({ label, value, suffix }, index) => {
            if (!!!value) return;
            return (
              <div className={`${mainClass}__content__info__item`} key={index}>
                <label>{label}</label>
                <span>
                  {value} {!!suffix && <span>{suffix}</span>}
                </span>
              </div>
            );
          })}
          <div className={`${mainClass}__content__info__item__actions`}>
            <Button href={uri}>Szczegóły...</Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvestmentListItem;
