import priceFormat from "./priceFormat";
import roomSuffixRender from "./roomSuffixRender";

const investmentTileParser = (data) => {
  const formatted_investment = data?.map((item) => {
    const formatted_price = priceFormat(
      item?.investments?.priceMin,
      item?.investments?.currency
    );

    return {
      ...item,
      items: [
        {
          key: "price",
          label: "Cena od:",
          value: formatted_price?.price,
          suffix: formatted_price?.suffix,
        },
        {
          key: "price",
          label: "Cena",
          value: priceFormat(
            item?.apartments?.price,
            item?.investments?.currency
          )?.price,
          suffix: priceFormat(
            item?.apartments?.price,
            item?.investments?.currency
          )?.suffix,
        },
        {
          label: "Stopa zwrotu:",
          value: !!item?.investments?.subdivisionModel
            ? "Model podziałowy"
            : item?.investments?.backPercentage,
          suffix: !!item?.investments?.subdivisionModel ? null : "%",
        },
        {
          label: "Powierzchnia od:",
          value: item?.investments?.surfacemin,
          suffix: "m²",
        },
        {
          label: "Oddanie inwestycji:",
          value: item?.investments?.deadline,
        },
        {
          label: "Długość umowy:",
          value: item?.investments?.contactLength,
          suffix: "lat",
        },
        {
          label: "Ilość pokoi:",
          value: item?.apartments?.rooms,
          suffix: roomSuffixRender(item?.apartments?.rooms),
        },
        {
          label: "Powierzchnia:",
          value: item?.apartments?.surface,
          suffix: "m2",
        },
      ],
    };
  });

  return formatted_investment;
};

export default investmentTileParser;
