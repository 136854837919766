import "styles/pages/home-page.scss";

import React, { useState } from "react";
import { graphql } from "gatsby";

import Layout from "components/Layout";
import HeaderWithOptions from "../components/HeaderWithOptions";
import StorageContent from "page_components/storage/Content";

import HeartIcon from "icons/heart.svg";

const Storage = ({ data }) => {
  const [selected_option, setSelectedOption] = useState(0);

  const all_investments = data?.allWpInvestment?.nodes;
  const all_apartments = data?.allWpApartament?.nodes;

  return (
    <Layout location="Schowek" blue_header seo={{ title: "Schowek" }}>
      <HeaderWithOptions
        icon={<HeartIcon />}
        title="Schowek"
        selected_option_text="Wybierz opcję swojej listy"
        selected_option={selected_option}
        setSelectedOption={setSelectedOption}
      />
      <StorageContent
        all_investments={all_investments}
        all_apartments={all_apartments}
        selected_view={selected_option}
      />
    </Layout>
  );
};

export default Storage;

export const query = graphql`
  query favInvestments {
    allWpInvestment {
      nodes {
        title
        id
        uri
        slug
        investments {
          contactLength
          deadline
          investLogo {
            sourceUrl
            altText
          }
          mainImg {
            sourceUrl
          }
          mainTitle
          priceMin
          vacationsDaysCustom
          subtitle
          shortLocation
          surfacemin
          backPercentage
          subdivisionModel
          labelState
          labelText
        }
      }
    }
    allWpApartament {
      nodes {
        title
        id
        uri
        slug
        apartments {
          investment {
            ... on WpInvestment {
              id
              title
              uri
              slug
              investments {
                mainTitle
                mainImg {
                  sourceUrl
                  altText
                }
                investLogo {
                  altText
                  sourceUrl
                }
                contactLength
                deadline
                shortLocation
                backPercentage
                subdivisionModel
                hidePrice
                vacationsDaysCustom
                surfacemin
              }
            }
          }
          mainTitle
          price
          rooms
          surface
          floor
        }
      }
    }
  }
`;
