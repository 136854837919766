import "./styles.scss";

import React, { useState, useEffect } from "react";
import { Link } from "gatsby";

import Button from "components/Button";
import InvestmentListItem from "components/InvestmentListItem";
import InvestmentGridItem from "components/InvestmentGridItem";

import investmentTileParser from "utils/investmentTileParser";
import { useFavoriteInvestments } from "context/favorite_investments";
import { useFavoriteApartments } from "../../../context/favorite_apartments";

const mainClass = "storage-content";

const item_view_render = (index, props, item_view, selected_view) => {
  switch (item_view) {
    case "grid":
      return <InvestmentGridItem key={index} {...props} popup_form />;
    default:
      return (
        <InvestmentListItem
          key={index}
          {...props}
          popup_form
          selected_view={selected_view}
        />
      );
  }
};

const ComparePlaceholder = () => {
  return (
    <div className={`${mainClass}__placeholder`}>
      <h2>Dodaj inwestycje do schowka</h2>
      <Link to="/investments/">
        <Button>Wyszukiwarka</Button>
      </Link>
    </div>
  );
};

const StorageContent = ({ all_investments, all_apartments, selected_view }) => {
  const [item_view, setItemView] = useState("list");

  const { fav_investments } = useFavoriteInvestments();
  const { fav_apartments } = useFavoriteApartments();

  const filttered_investments = all_investments?.filter((item) =>
    fav_investments?.includes(item?.id)
  );

  const filttered_apartments = all_apartments
    ?.filter((item) => fav_apartments?.includes(item?.id))
    ?.map((item) => ({
      ...item,
      ...item?.apartments?.investment[0],
      id: item?.id,
      title: item?.apartments?.mainTitle,
    }));

  const investments_data = investmentTileParser(filttered_investments);
  let apartments_data = investmentTileParser(filttered_apartments);

  const data_to_show = selected_view === 0 ? investments_data : apartments_data;

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth <= 1160) {
        if (item_view !== "grid") {
          setItemView("grid");
        }
      } else {
        if (item_view !== "list") {
          setItemView("list");
        }
      }
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  return (
    <div className={mainClass}>
      <div className="container">
        {selected_view === 0 ? (
          fav_investments.length !== 0 ? (
            data_to_show?.map((props, index) =>
              item_view_render(index, props, item_view, selected_view)
            )
          ) : (
            <ComparePlaceholder />
          )
        ) : fav_apartments.length !== 0 ? (
          data_to_show?.map((props, index) =>
            item_view_render(index, props, item_view, selected_view)
          )
        ) : (
          <ComparePlaceholder />
        )}
      </div>
    </div>
  );
};

export default StorageContent;
